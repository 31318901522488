import * as React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { differenceInCalendarDays } from "date-fns";

const disabledDates = [new Date(2021, 4, 3), new Date(2021, 4, 4)];

function tileDisabled({ date, view }) {
  // Disable tiles in month view only
  // Check if a date React-Calendar wants to check is on the list of disabled dates
  return disabledDates.find((dDate) => isSameDay(dDate, date));
}

function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0;
}

//On date click
function OnDateClick(value, event) {
  let date = value;
  alert("New date is: " + value);
  console.log(date);
}

// Markup
const CalanderPage = () => {
  return (
    <React.Fragment>
      <h1>Calander</h1>
      <Calendar
        onChange={OnDateClick}
        value={new Date()}
        calendarType={"US"}
        maxDetail={"month"}
        tileDisabled={tileDisabled}
        locale={"es-419"}
      />
    </React.Fragment>
  );
};
export default CalanderPage;
